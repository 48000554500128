<!-- 审核弹窗弹窗 -->
<template>
  <el-dialog
      width="40%"
      center
      :visible="visible"
      :lock-scroll="false"
      :destroy-on-close="true"
      custom-class="ele-dialog-form"
      :title="isUpdate?'订单审核':'订单审核'"
      @update:visible="updateVisible">
    <el-form
        ref="form"
        :model="form"
        label-width="100px">

      <el-row :gutter="15" v-if="status == 0">
        <el-col :span="12">
          <div style="background: #36dd9e;border-radius: 6px;width: 140px;padding-top: 10px;padding-bottom: 10px;text-align: center;cursor: pointer;margin: auto;">
            <img src="@/assets/images/home/hege.png" alt="" style="width: 24px;height: 24px;vertical-align: middle;">
            <span style="font-size: 20px;font-weight: 500;color: #ffffff; vertical-align: middle;margin-left: 10px;">合格</span>
          </div>
        </el-col>
        <el-col :span="12">
          <div style="background: #DADADA;border-radius: 6px;width: 140px;padding-top: 10px;padding-bottom: 10px;text-align: center;cursor: pointer;margin: auto;" @click="Unqualified(1)">
            <img src="@/assets/images/home/buhege.png" alt="" style="width: 24px;height: 24px;vertical-align: middle;">
            <span style="font-size: 20px;font-weight: 500;color: #3F4155; vertical-align: middle;margin-left: 10px;">不合格</span>
          </div>
        </el-col>
      </el-row>
      <el-row v-if="status == 0" style="margin-top: 20px;">
        <el-col :span="8">
          <div style="font-size: 16px;font-weight: 500;color: #4e4e4e;text-align: center;">
            <span>合同金额：</span>
            <span>{{form.contract_price}}</span>
          </div>
        </el-col>
        <el-col :span="8">
          <div style="font-size: 16px;font-weight: 500;color: #4e4e4e;text-align: center;">
            <span>合计补扣款：</span>
            <span>{{RepairthreadComputed}}</span>
          </div>
        </el-col>
        <el-col :span="8">
          <div style="font-size: 16px;font-weight: 500;color: #4e4e4e;text-align: center;">
            <span>应付额：</span>
            <span>{{dealwithComputed}}</span>
          </div>
        </el-col>
      </el-row>
      <el-row v-if="status == 0">
        <div style="background: #f7f8fa;border-radius: 6px;padding: 15px;margin-top: 20px;margin-bottom: 20px;">
          <el-row v-for="(item,index) in form.pass" :key="index">
            <el-col :span="12" style="margin-top: 7px;">
              <el-checkbox v-model="item.checked" @change="selectcheckbox(item,index)">{{item.label}}</el-checkbox>
            </el-col>
            <el-col :span="12">
              <el-form-item label="补扣款">
                <el-input type="number" v-model.number="item.figure" placeholder="请输入" :disabled="!item.checked" @input="update" oninput="value=value.replace(/^0|[^0-9]/g,'')">
                  <template slot="append">
                    <span v-if="item.mode == 'proportion'">百分比</span>
                    <span v-else>固定金额</span>
                  </template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12" style="margin-top: 7px;">
              <el-checkbox v-model="checked1" @change="selectcheckbox">其他</el-checkbox>
            </el-col>
            <el-col :span="12">
              <el-form-item label="补扣款">
                <el-input type="number" v-model="kuanxiang" placeholder="请输入" :disabled="!checked1"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="checked1 == true">
            <el-input
                type="textarea"
                :rows="2"
                placeholder="请输入其他原因"
                v-model="form.other">
            </el-input>
          </el-row>
        </div>
      </el-row>


      <el-row :gutter="15" v-if="status == 1">
        <el-col :span="12">
          <div style="background: #DADADA;border-radius: 6px;width: 140px;padding-top: 10px;padding-bottom: 10px;text-align: center;cursor: pointer;margin: auto;" @click="Unqualified(0)">
            <img src="@/assets/images/home/hegeactive.png" alt="" style="width: 24px;height: 24px;vertical-align: middle;">
            <span style="font-size: 20px;font-weight: 500;color: #3F4155; vertical-align: middle;margin-left: 10px;">合格</span>
          </div>
        </el-col>
        <el-col :span="12">
          <div style="background: #F54E71;border-radius: 6px;width: 140px;padding-top: 10px;padding-bottom: 10px;text-align: center;cursor: pointer;margin: auto;" @click="Unqualified()">
            <img src="@/assets/images/home/buhegeactive.png" alt="" style="width: 24px;height: 24px;vertical-align: middle;">
            <span style="font-size: 20px;font-weight: 500;color: #FFFFFF; vertical-align: middle;margin-left: 10px;">不合格</span>
          </div>
        </el-col>
      </el-row>
      <el-row v-if="status == 1" style="margin-top: 20px;">
        <el-col :span="8">
          <div style="font-size: 16px;font-weight: 500;color: #4e4e4e;text-align: center;">
            <span>合同金额：</span>
            <span>{{form.contract_price}}</span>
          </div>
        </el-col>
        <el-col :span="8">
          <div style="font-size: 16px;font-weight: 500;color: #4e4e4e;text-align: center;">
            <span>合计补扣款：</span>
            <span>{{RepairthreadComputed}}</span>
          </div>
        </el-col>
        <el-col :span="8">
          <div style="font-size: 16px;font-weight: 500;color: #4e4e4e;text-align: center;">
            <span>应付额：</span>
            <span>{{dealwithComputed}}</span>
          </div>
        </el-col>
      </el-row>
      <el-row v-if="status == 1">
        <div style="background: #f7f8fa;border-radius: 6px;padding: 15px;margin-top: 20px;margin-bottom: 20px;">
          <el-row v-for="(item,index) in form.flunk" :key="index">
            <el-col :span="12" style="margin-top: 7px;">
              <el-checkbox v-model="item.checked" @change="buhegeselectcheckbox(item,index)">{{item.label}}</el-checkbox>
            </el-col>
            <el-col :span="12">
              <el-form-item label="补扣款">
                <el-input type="number" v-model.number="item.figure" placeholder="请输入" :disabled="!item.checked" @input="update" oninput="value=value.replace(/^0|[^0-9]/g,'')">
                  <template slot="append">
                    <span v-if="item.mode == 'proportion'">百分比</span>
                    <span v-else>固定金额</span>
                  </template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12" style="margin-top: 7px;">
              <el-checkbox v-model="checked2" @change="buhegeselectcheckbox">其他</el-checkbox>
            </el-col>
            <el-col :span="12">
              <el-form-item label="补扣款">
                <el-input type="number" v-model="kuanxiang" placeholder="请输入" :disabled="!checked2"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="checked2 == true">
            <el-input
                type="textarea"
                :rows="2"
                placeholder="请输入其他原因"
                v-model="form.other">
            </el-input>
          </el-row>
        </div>
      </el-row>

    </el-form>
    <div slot="footer">
      <el-button
          @click="updateVisible(false)">取消
      </el-button>
      <el-button
          style="margin-left: 20px;"
          type="primary"
          :loading="loading"
          v-auths="[`${$config.uniquePrefix}orderModule:toBeReviewed:audit`]"
          @click="save">确定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import {audit, disqualification, getcheck_assist} from "@/api/orderModule";

export default {
  name: 'UserEdit',
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  components: { },
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,

      //补扣额
      Repairthread:0,
      kuanxiang:0,

      //应付款
      dealwith:0,

      status:0,

      checked1:false,
      checked2:false,

      //合格and不合格 pass为合格  flunk为不合格
      type:'pass',
      // 传递的数组 那些选中了
      repair_thread:[],

    };
  },
  watch: {
  },

  computed: {
    // kuanxiang(){
    //   return parseFloat(this.kuanxiang) + parseFloat(this.Repairthread)
    // },
    RepairthreadComputed(){
      if(this.kuanxiang == 0 && this.kuanxiang == ''){
        return this.Repairthread
      }
      return parseFloat(this.kuanxiang) + parseFloat(this.Repairthread)
    },

    dealwithComputed(){

      // return parseFloat(this.Repairthread) + parseFloat(this.data.contract_price)
      return parseFloat(this.Repairthread) + parseFloat(this.form.contract_price) + parseFloat(this.kuanxiang)
    }

  },

  mounted() {
    if (this.data) {
      console.log(this.data)
      this.form = {};
      this.isUpdate = true;
    } else {
      this.form = {};
      this.isUpdate = false;
    }

    //调用获取配置
    this.getConfig();

  },
  methods: {
    // 获取审核配置
    getConfig(){
      let where = {
        odd: this.data.order_id
      }
      getcheck_assist(this.data.id,where).then(res => {
        console.log(res);
        this.form = res.data;
      })
    },

    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          // 审核合格
          if(this.type == 'pass'){
            let where = {
              odd: this.data.order_id,
              repair_thread_price: this.RepairthreadComputed,
              other: this.form.other,
              repair_thread: this.repair_thread,
            }
            audit(this.data.id,where).then(res => {
              if(res.code == 200){
                this.loading = true;
                this.updateVisible(false);
                this.$emit('done');
                this.loading = false;
                this.$message.success(res.msg);
              }else {
                this.loading = false;
                this.$message.error(res.msg)
              }
            }).catch(e => {
              this.loading = false;
              this.$message.error(e.msg)
            })
          }else {
            let where = {
              odd: this.data.order_id,
              repair_thread_price: this.RepairthreadComputed,
              other: this.form.other,
              repair_thread: this.repair_thread,
            }
            disqualification(this.data.id,where).then(res => {
              if(res.code == 200){
                this.loading = true;
                this.updateVisible(false);
                this.$emit('done');
                this.loading = false;
                this.$message.success(res.msg);
              }else {
                this.loading = false;
                this.$message.error(res.msg)
              }
            }).catch(e => {
              this.loading = false;
              this.$message.error(e.msg)
            })
          }


        } else {
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },

    //选择类型
    activeItem(item,index){
      console.log(item);
      console.log(index);
      this.isShow = index;
      this.repair_thread = [];
    },

    //合格 点击多选
    selectcheckbox(item,index){
      // console.log(item);
      // proportion百分比  immobilization固定

      //用户传递的数组
      this.repair_thread = [];
      // 声明一个用于存放固定金额勾选的数据
      let data = [];
      // 百分比
      // let percentage = [];
      // 循环获取那些项勾选了
      this.form.pass.forEach(item => {
        if(item.checked == true){
          // 判断是固定金额还是百分比
          if(item.mode == 'immobilization'){
            // 将拿到的值转换float类型用于计算
            // item.figure = parseFloat(item.figure);
            data.push(item.figure)
          }else if(item.mode == 'proportion'){
            console.log(item)
            this.form.contract_price = parseFloat(this.form.contract_price);
            // item.figure = parseFloat(item.figure);
            let a = item.figure / 100;
            let b = a * this.form.contract_price;
            data.push(b)
          }
          //那些项选中了
          this.repair_thread.push(item);
        }
      })
      // 其他是否勾选了
      if(this.checked1 == true){
        console.log(this.kuanxiang)
        this.kuanxiang = parseFloat(this.kuanxiang);
        data.push(this.kuanxiang);
      }
      console.log(data);
      //计算总和
      this.Repairthread = data.reduce(function(acc, val) {
        return acc + val;
      }, 0);
      this.Repairthread = parseFloat(this.Repairthread);
      console.log(this.Repairthread,'合同补扣款')

      // 应付款
      // this.dealwith = parseInt(this.data.contract_price) + parseInt(this.Repairthread);
    },

    //点击不合格
    Unqualified(number){
      this.status = number;
      if(this.status == 0){
        this.type = 'pass'
        this.form.pass.forEach(item => {
          item.checked = false;
        })
        this.checked1 = false;
      }else {
        this.type = 'flunk'
        this.form.flunk.forEach(item => {
          item.checked = false;
        })
        this.checked2 = false;
      }
      this.kuanxiang = 0;
      this.Repairthread = 0;
    },

    //选择不合格的项
    buhegeselectcheckbox(item,index){
      //用户传递的数组
      this.repair_thread = [];
      // 声明一个用于存放固定金额勾选的数据
      let data = [];
      // 循环获取那些项勾选了
      this.form.flunk.forEach(item => {
        if(item.checked == true){
          // 判断是固定金额还是百分比
          if(item.mode == 'immobilization'){
            // 将拿到的值转换float类型用于计算
            // item.figure = parseFloat(item.figure);
            data.push(item.figure)
          }else if(item.mode == 'proportion'){
            console.log(item)
            this.form.contract_price = parseFloat(this.form.contract_price);
            // item.figure = parseFloat(item.figure);
            let a = item.figure / 100;
            let b = a * this.form.contract_price;
            console.log(item.figure)
            data.push(b)
          }
          //那些项选中了
          this.repair_thread.push(item);
        }
      })
      // 其他是否勾选了
      if(this.checked2 == true){
        console.log(this.kuanxiang)
        this.kuanxiang = parseFloat(this.kuanxiang);
        data.push(this.kuanxiang);
      }
      console.log(data);
      //计算总和
      this.Repairthread = data.reduce(function(acc, val) {
        return acc + val;
      }, 0);
      this.Repairthread = parseFloat(this.Repairthread);
      console.log(this.Repairthread,'合同补扣款')

      // 应付款
      // this.dealwith = parseInt(this.data.contract_price) + parseInt(this.Repairthread);
    },

    update(value){
      parseFloat(value)
      console.log(value)
      this.selectcheckbox();
    }

  }
}
</script>

<style scoped lang="scss">

</style>
